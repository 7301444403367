export const navigation = [
  {
    text: 'Ana Sayfa',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'POS',
    path: '/pos',
    icon: 'mediumiconslayout'
  },
  {
    text: 'Mutfak',
    path: '/kitchen',
    icon: 'verticalaligntop'
  },
  {
    text: 'Siparişler',
    path: '/orders',
    icon: 'bulletlist'
  },
  {
    text: 'Ürünler',
    path: '/products',
    icon: 'coffee'
  },
  {
    text: 'Ürün Yönetimi',
    icon: 'edittableheader',
    items: [
      {
        text: 'Kategoriler',
        path: '/products/categories'
      },
      {
        text: 'Opsiyon Grupları',
        path: '/products/attributes'
      }
    ]
  },
  {
    text: 'Kuponlar',
    path: '/coupons',
    icon: 'gift'
  },
  {
    text: 'Masalar',
    path: '/tables',
    icon: 'rowfield'
  },
  {
    text: 'Kullanıcı Yönetimi',
    icon: 'edittableheader',
    items: [
      {
        text: 'Yöneticiler',
        path: '/users/admins'
      },
      {
        text: 'Çalışanlar',
        path: '/users/employees'
      },
      {
        text: 'Müşteriler',
        path: '/users/customers'
      }
    ]
  },
  {
    text: 'Vergiler',
    path: '/taxes',
    icon: 'percent'
  },
  {
    text: 'Raporlar',
    icon: 'doc',
    items: [
      {
        text: 'Gün Sonu Raporu',
        path: '/reports/daily-summary'
      },
      {
        text: 'Satış Raporu',
        path: '/reports/sell-summary'
      },
      {
        text: 'Ürün Raporu',
        path: '/reports/product-summary'
      },
      {
        text: 'Satış Kanalı Raporu',
        path: '/reports/channel-summary'
      },
    ]
  },
  {
    text: 'Ayarlar',
    icon: 'more',
    items: [
      {
        text: 'Mağaza',
        path: '/restaurant'
      },
      {
        text: 'Profil',
        path: '/profile'
      },
      {
        text: 'Abonelik',
        path: '/tasks'
      }
    ]
  }
];
