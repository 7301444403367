import { Component, OnInit } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';
import { ItemRequest } from "src/app/shared/models/item.model";
import { Router } from "@angular/router";

@Component({
    selector: 'app-product-list',
    templateUrl: `./product-list.component.html`,
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  dataSource: any[] = [];

  categoryDataSource: any[] = [];
  taxDataSource: any[] = [];

  image: File[] = [];

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;  
  itemCategory: ItemRequest = new ItemRequest();


  get isFormValid(): boolean {
    return this.itemCategory.name 
    && this.itemCategory.name.length > 0 
    && this.itemCategory.status != null
    && this.itemCategory.itemCategoryId != null
    && this.itemCategory.price != null
    && this.itemCategory.isFeatured != null
  }

  constructor(private httpService: HttpService, private router: Router) {}

  ngOnInit(): void {
    this.getItems();
    this.getCaegories();
  }


  getItems() {
    this.httpService
      .get(`Item`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        res.forEach(element => {
            element.description = !element.description ? "-": element.description;
        });

        this.dataSource = res;
      });
  }

  getCaegories() {
    this.httpService
      .get(`ItemCategory`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.categoryDataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`Item/${id}`).subscribe(res => {
      this.itemCategory = res;
      this.openPopup();
    })
  }

  onViewClicked(id: string) {
    this.router.navigate([`/products/detail/${id}`]);
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`Item/${id}`).subscribe(res => {
      this.getItems();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.itemCategory.id) {
      this.httpService.put('Item', this.itemCategory).subscribe(res => {
        this.getItems();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('Item', this.itemCategory).subscribe(res => {
        this.getItems();
        this.onCloseClicked();
      })
    }


  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.itemCategory = new ItemRequest();
  }.bind(this);




}
