import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertUtcToLocal'
})
export class ConvertUtcToLocalPipe implements PipeTransform {

  transform(value: Date | string | number): Date {
    // Convert the input value to a Date object
    const utcDate = new Date(value);

    // Get the local time offset in minutes and convert to milliseconds
    const localOffset = utcDate.getTimezoneOffset() * 60000;

    // Create a new Date object for the local time
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
}