<div class="user-panel">
  <div class="user-info">
    <div class="image-container">
      <div class="user-image" [ngStyle]="{'background-image': 'url(' + user?.avatarUrl + ')'}"></div>
    </div>
    <!-- <div class="user-name dx-theme-text-color">{{user?.email}}</div> -->
    <div class="user-name dx-theme-text-color">{{user?.restaurantName}}</div>
  </div>
  <dx-context-menu
    *ngIf="menuMode === 'context'"
    [items]="menuItems"
    target=".user-button"
    showEvent="dxclick"
    width="210px"
    [position]="{
      my: 'top',
      at: 'bottom'
    }"
    cssClass="user-menu">
  </dx-context-menu>
  <dx-list
    *ngIf="menuMode === 'list'"
    class="dx-toolbar-menu-action"
    [items]="menuItems">
  </dx-list>
</div>
