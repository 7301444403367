<dx-popup
  class="pos-screen"
  [width]="'100%'"
  [height]="'100vh'"
  [showTitle]="true"
  title="POS"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="false"
  container=".dx-viewport"
  [visible]="true"
>
  <dxi-toolbar-item
    toolbar="top"
    location="after"
    widget="dxButton"
    [options]="{
      type: 'normal',
      icon: 'close',
      onClick: back
    }"
  >
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <div *ngIf="availableCategories.length > 0" class="row">
      <div class="col-md-2 border-radious">
        <dx-accordion
        class="full_height"
        #accordion
        [dataSource]="orderDataSource"
        [collapsible]="true"
        [multiple]="false"
        [selectedItems]="[]"
        [animationDuration]="300"
      >
        <div *dxTemplate="let order of 'title'">
          <div class="header">{{ order.summary }}</div>
        </div>
        <div *dxTemplate="let order of 'item'">
          <div>
            <p>
              <b>No: </b>
              <span>2024051901</span>
            </p>
            <p>
              <span>Masa: 15</span>
              <br>
              <span>Tutar: 357.00</span>
            </p>
          </div>
          <div>
            <p>Durum: <b>Hazırlanıyor</b></p>
            <p>Kanal: <b>POS</b></p>
          </div>

          <div class="mt-2">
            <dx-button
              class="mt-2"
              [text]="'Göster'"
              icon="search"
              [stylingMode]="'outlined'"
              type="normal"
              width="100%"
            >
            </dx-button>
            <dx-button
              class="mt-2"
              icon="edit"
              [text]="'Güncelle'"
              [stylingMode]="'contained'"
              type="default"
              width="100%"
            >
            </dx-button>

            <div class="d-flex mt-2">
              <dx-button
                icon="print"
                [text]="'Yazdır'"
                [stylingMode]="'outlined'"
                type="default"
                width="100%"
              >
              </dx-button
              ><dx-button
                [text]="'Sil'"
                icon="trash"
                [stylingMode]="'outlined'"
                type="danger"
                width="100%"
              >
              </dx-button>
            </div>
          </div>
        </div>
      </dx-accordion>
      </div>

      <div class="col-md-4 pl-0">
        <div class="w-100 bg-white full_height border-radious">
          <img src="assets/mock-detail.png" class="w-100" alt="" style="height: 100%;">
        </div>
      </div>

      <div class="col-md-6">
        <div class="row bg-white border-radious">
          <div class="col-12 mb-2 px-0">
            <dx-text-box id="search-pos" [placeholder]="'Ara'"></dx-text-box>
          </div>

          <div class="col-md-2 border category_holder">
            <dx-button
              *ngFor="let item of availableCategories"
              class="mt-2"
              [text]="item"
              [stylingMode]="
                selectedCategory == item ? 'contained' : 'outlined'
              "
              type="default"
              (onClick)="changeCategory(item)"
              [width]="150"
            >
            </dx-button>
          </div>

          <div class="col-md-10 px-0">
            <div class="item_holder full_height_item_holder">
              <div class="category_items">
                <div class="specific_category_items_holder">
                  <div
                    [id]="'item-card-' + item.id"
                    class="single_item"
                    *ngFor="let item of filteredItems"
                  >
                    <img
                      [src]="item?.image ?? 'assets/placeholder.png'"
                      width="142"
                    />
                    <p class="item_name">
                      {{ item.name }}
                    </p>
                    <p class="item_price">
                      Fiyat:
                      <span class="price">{{
                        item.price | number : "1.2"
                      }}</span>
                    </p>

                    <dx-tooltip
                      [target]="'#item-card-' + item.id"
                      showEvent="mouseenter"
                      position="right"
                      hideEvent="mouseleave"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data = data; of: 'content'">
                        {{ item.name }}
                      </div>
                    </dx-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>
