import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpService } from './http.service';
import { firstValueFrom } from 'rxjs';

export interface IUser {
  email: string;
  avatarUrl?: string,
  restaurantName: string
}

const defaultPath = '/';
const defaultUser = {
  email: '',
  restaurantName: '',
  avatarUrl: 'https://siparissaati.com.tr/storage/124/conversions/Screenshot-2024-03-04-at-03.12.27-thumb.png'
};

@Injectable()
export class AuthService {
  private _user: IUser | null = JSON.parse(localStorage.getItem('currentUser'));
  get loggedIn(): boolean {
    return !!this._user;
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(private router: Router, private httpService: HttpService) { 
  }

  async logIn(email: string, password: string) {
    try {
      const userData: any = await firstValueFrom(this.httpService.post('Authentication', { email: email, password: password}));

      if (!userData.token) {
        return {
          isOk: false,
          message: "Giriş yapma başarısız"
        };
      }

      this._user = {email: email, restaurantName: ''};
      localStorage.setItem('currentUser', JSON.stringify(this._user));
      localStorage.setItem('token', userData.token);
      this.router.navigate([this._lastAuthenticatedPath]);

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false,
        message: "Giriş yapma başarısız"
      };
    }
  }

  async getUser() {
    try {
      const userData: any = await firstValueFrom(this.httpService.get('Authentication'));

      if (!!userData.email) { 
        this._user = {
          email: userData.email,
          avatarUrl: defaultUser.avatarUrl,
          restaurantName: userData.restaurantName
        }

        localStorage.setItem('currentUser', JSON.stringify(this._user));
        return {
          isOk: !!userData.email,
          data: this._user
        };
      } 
      
      this._user = null;
      localStorage.removeItem('currentUser')
      this.router.navigate(['/login-form']);
      return {
        isOk: false,
        data: null
      };
    }
    catch {
      this._user = null;
      localStorage.removeItem('currentUser')
      this.router.navigate(['/login-form']);

      return {
        isOk: false,
        data: null
      };
    }
  }

  async createAccount(email: string, password: string) {
    try {
      this.router.navigate(['/create-account']);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    };
  }

  async resetPassword(email: string) {
    try {

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  }

  async logOut() {
    this._user = null;
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    this.router.navigate(['/login-form']);
  }

  getAuthorizationToken() {
    return localStorage.getItem('token')??'';
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'change-password/:recoveryCode'
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login-form']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig?.path || defaultPath;
    }

    return isLoggedIn || isAuthForm;
  }
}
