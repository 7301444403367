import { CommonModule } from '@angular/common';
import { Component, Input, type OnInit } from '@angular/core';

@Component({
    selector: 'app-order-print',
    templateUrl: './order-print.component.html',
    styleUrls: ['./order-print.component.scss'],
})
export class OrderPrintComponent implements OnInit {
    @Input() order: any;
    
    ngOnInit(): void { }

    hasNote() {
        return this.order.note != null || this.order.orderItems.some(x => x.instruction != null);
    }
 }
