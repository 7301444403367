import { Component } from "@angular/core";

const msInDay = 1000 * 60 * 60 * 24;
const now = new Date();
const initialValue: [Date, Date] = [
  new Date(now.getTime() - msInDay * 3),
  new Date(now.getTime() + msInDay * 3),
];

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  currentValue: any = initialValue;

  tabsWithText: any[] = [
    {
      id: 0,
      text: "Günlük",
    },
    {
      id: 1,
      text: "Haftalık",
    },
    {
      id: 2,
      text: "2 Haftalık",
    },
    {
      id: 3,
      text: "Aylık",
    },
    {
      id: 4,
      text: "Tüm Zamanlar",
    },
  ];

  salesInfo: any[] = [
    {
      title: "Ocak",
      pos: 598,
      yemeksepeti: 37.6,
      siparissaati: 182,
      getir: 164.3,
      trendyol: 287.9,
    },
    {
      title: "Şubat",
      pos: 899.8,
      yemeksepeti: 237.6,
      siparissaati: 482,
      getir: 364.3,
      trendyol: 187.9,
    },
    {
      title: "Mart",
      pos: 1598,
      yemeksepeti: 537.6,
      siparissaati: 382,
      getir: 164.3,
      trendyol: 287.9,
    },
    {
      title: "Nisan",
      pos: 559.8,
      yemeksepeti: 237.6,
      siparissaati: 382,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Mayıs",
      pos: 59.8,
      yemeksepeti: 337.6,
      siparissaati: 482,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Haziran",
      pos: 509.8,
      yemeksepeti: 737.6,
      siparissaati: 382,
      getir: 164.3,
      trendyol: 287.9,
    },
    {
      title: "Temmuz",
      pos: 590.8,
      yemeksepeti: 937.6,
      siparissaati: 582,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Ağustos",
      pos: 59.8,
      yemeksepeti: 937.6,
      siparissaati: 582,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Eylül",
      pos: 59.8,
      yemeksepeti: 937.6,
      siparissaati: 582,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Ekim",
      pos: 208,
      yemeksepeti: 940,
      siparissaati: 300,
      getir: 500,
      trendyol: 10,
    },
    {
      title: "Kasım",
      pos: 900,
      yemeksepeti: 937.6,
      siparissaati: 100,
      getir: 564.3,
      trendyol: 87,
    },
    {
      title: "Aralık",
      pos: 598,
      yemeksepeti: 937,
      siparissaati: 82,
      getir: 564,
      trendyol: 189,
    },
  ];

  saleChannels: any[] = [
    {
      title: "Pos",
      total: 12000,
    },
    {
      title: "Yemek Sepeti",
      total: 5000,
    },
    {
      title: "Sipariş Saati",
      total: 2320,
    },
    {
      title: "Getir",
      total: 3000,
    },
    {
      title: "Trendyol",
      total: 1275,
    },
  ];

  mostSellingItems: any[] = [
    {
      ID: 1,
      "Ürün": "Tavuk Döner Dürüm Menü 75gr",
      "Sipariş Sayısı": 250,
      "Toplam Tutar": 2375
    },
    {
      ID: 2,
      "Ürün": "Et Döner Dürüm Menü 75gr",
      "Sipariş Sayısı": 125,
      "Toplam Tutar": 2500
    },
    {
      ID: 3,
      "Ürün": "Ayran",
      "Sipariş Sayısı": 120,
      "Toplam Tutar": 650
    },
    {
      ID: 4,
      "Ürün": "Hamburger 150gr",
      "Sipariş Sayısı": 100,
      "Toplam Tutar": 3000
    },
  ];
  constructor() {
    this.salesInfo.forEach((element) => {
      element["total"] =
        element.pos +
        element.yemeksepeti +
        element.getir +
        element.siparissaati +
        element.trendyol;
    });
  }

  customizeTooltip = ({ percentText, valueText }) => ({
    text: `${percentText ?? "Toplam"} - ${valueText} TL`,
  });

  customizeLabel(arg) {
    return `${arg.valueText}TL (${arg.percentText})`;
  }
}
