import { HttpParams } from "@angular/common/http";
import { Status } from "./status.enum";

export enum OrderStatus {
    FutureOrderPendingApproval = 1,

    FutureOrderApproved = 2,

    RestaurantApprovalPending = 3,

    OrderPreparing = 4,

    OrderPrepared = 5,

    OrderOutForDelivery = 6,

    CourierOnTheWay = 7,

    CourierArrivedAtDestination = 8,

    OrderDelivered = 9,

    OrderCancelledByAdmin = 10, 

    OrderCancelledByRestaurant = 11,

    Accept = 12
}


export default class OrderStatusUtils { 
    static GetOrderStatusParams = (statuses: OrderStatus[]) => {
        let params = new HttpParams();
        statuses.forEach((status) => {
          params = params.append("orderStatuses", status.toString());
        });
    
        return params;
    };

    static GetOrderAndStatusParams = (orderstatuses: OrderStatus[]) => {
        let params = new HttpParams();
        [Status.Active, Status.Passive].forEach((status) => {
            params = params.append("statuses", status.toString());
        });
        orderstatuses.forEach((status) => {
          params = params.append("orderStatuses", status.toString());
        });
        return params;
    };


    static StatusNamesLookup = [ 
        {id: OrderStatus.Accept, name: 'Sipariş onaylandı' }, 
        {id: OrderStatus.FutureOrderPendingApproval, name: 'İleri tarihli sipariş, ön onay bekliyor' }, 
        {id: OrderStatus.FutureOrderApproved, name: 'İleri tarihli sipariş, ön onay alındı' }, 
        {id: OrderStatus.RestaurantApprovalPending, name: 'Restoran onayı bekleniyor' }, 
        {id: OrderStatus.OrderPreparing, name: 'Sipariş hazırlanıyor' }, 
        {id: OrderStatus.OrderPrepared, name: 'Sipariş hazırlandı' }, 
        {id: OrderStatus.OrderOutForDelivery, name: 'Sipariş kuryeye teslim edildi' }, 
        {id: OrderStatus.CourierOnTheWay, name: 'Kurye yola çıktı' }, 
        {id: OrderStatus.CourierArrivedAtDestination, name: 'Kurye adrese ulaştı' }, 
        {id: OrderStatus.OrderDelivered, name: 'Sipariş teslim edildi' }, 
        {id: OrderStatus.OrderCancelledByAdmin, name: 'Sipariş admin tarafından iptal edildi' }, 
        {id: OrderStatus.OrderCancelledByRestaurant, name: 'İptal edildi' }, 
    ]

    static NewOrderStatuses = [OrderStatus.RestaurantApprovalPending, OrderStatus.FutureOrderPendingApproval];
    static ActiveOrderStatuses = [
        OrderStatus.Accept, 
        OrderStatus.CourierArrivedAtDestination, 
        OrderStatus.CourierOnTheWay, 
        OrderStatus.FutureOrderApproved, 
        OrderStatus.OrderOutForDelivery, 
        OrderStatus.OrderPrepared,
        OrderStatus.OrderPreparing
    ];
    static CompletedOrderStatuses = [
        OrderStatus.OrderCancelledByAdmin, 
        OrderStatus.OrderCancelledByRestaurant, 
        OrderStatus.OrderDelivered, 
        OrderStatus.RestaurantApprovalPending
    ];

    static NextOrderStatusDict = {
        [OrderStatus.FutureOrderPendingApproval]: OrderStatus.FutureOrderApproved,
        [OrderStatus.RestaurantApprovalPending]: OrderStatus.Accept,
        [OrderStatus.Accept]: OrderStatus.OrderPreparing,
        [OrderStatus.OrderPreparing]: OrderStatus.OrderPrepared,
        [OrderStatus.OrderPrepared]: OrderStatus.CourierOnTheWay,
        [OrderStatus.CourierOnTheWay]: OrderStatus.OrderDelivered
    }


    static GetNextStatusText = (orderstatus: OrderStatus) => {
        return OrderStatusUtils.StatusNamesLookup.find(x => x.id == OrderStatusUtils.NextOrderStatusDict[orderstatus])?.name;
    };
}