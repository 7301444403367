<dx-data-grid
  class="w-100"
  [dataSource]="dataSource"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="false"
>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column
    dataField="provider"
    caption="Sağlayıcı"
    [width]="140"
    [allowGrouping]="false"
    dataField="thumb"
    [allowHiding]="false"
    cellTemplate="imageTemplate"
  >
  </dxi-column>

  <dxi-column dataField="client.clientFullName" [width]="200" caption="Müşteri">
  </dxi-column>
  <dxi-column
  [width]="350"
    dataField="client.address"
    caption="Adres"
    cellTemplate="addressTemplate"
  >
  </dxi-column>
  <dxi-column dataField="totalPrice" [width]="120" caption="Tutar">
  </dxi-column>
  <dxi-column dataField="paymentMethodName" caption="Ödeme Yöntemi">
  </dxi-column>
    <dxi-column
    dataField="status"
    caption="Durum"
    [width]="280"
    >
    <dxo-lookup
        [dataSource]="lookupStatus"
        valueExpr="id"
        displayExpr="name"
    ></dxo-lookup>
    </dxi-column>
  <!-- <dxi-column
    dataField="summary"
    caption="Sipariş Özeti"
    [width]="350"
    cellTemplate="orderSummaryTemplate"
  >
  </dxi-column> -->

  <dxi-column
    dataField="orderDatetime"
    caption="Tarih"
    dataType="datetime"
    [width]="150"
    cellTemplate="dateTemplate"
  >
  </dxi-column>



  <dxi-column
    dataField="id"
    caption="İşlemler"
    [width]="220"
    [allowGrouping]="false"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>

  <div *dxTemplate="let data of 'statusTemplate'">
    <app-status-badge [status]="data.value"></app-status-badge>
  </div>

  <div *dxTemplate="let data of 'addressTemplate'">
    <span class="badge badge-secondary">{{ data.data?.distance||5 }}km</span>
    <p class="mb-0" style="white-space: break-spaces;">{{ data.data?.client?.address || 'BİLİNMİYOR' }}{{ data.data?.client?.apartment || '' }}</p>
  </div>

  <div *dxTemplate="let data of 'orderSummaryTemplate'">
    <button class="btn btn-sm btn-secondary text-center" type="button" (click)="toggleCollapse(data.data.id)">
      Özet {{getSummaryText(data.data.id)}}
    </button>

    <div class="collapse" [id]="'summary-' + data.data.id">
      <ul class="list-group">
        <li class="list-group-item">Ekmek Arası Tavuk Döner</li>
        <li class="list-group-item">Zurna Tavuk Döner Dürüm Menü
          <ul class="list-group my-2">
            <li class="list-group-item text-muted">İçecek Tercihi: Cola</li>
            <li class="list-group-item text-muted">Çıkartılacak Malzeme: Marul, Domates</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div *dxTemplate="let data of 'dateTemplate'">
    <span class="badge" [ngClass]="(data.value | elapsedMinutes) > 30? 'badge-danger': 'badge-secondary'">{{ data.value | elapsedMinutes }} dk</span>
    <p class="mb-0">
      {{ data.value | date : "dd.MM.yyyy HH:mm" }}
    </p>
  </div>

  <div *dxTemplate="let data of 'providerTemplate'">
    <dx-button icon="link" [type]="data.value ? 'success' : 'normal'">
    </dx-button>
  </div>

  <div *dxTemplate="let data of 'imageTemplate'">
    <img
      class="placeholder-img w-100"
      [src]="
        data.value ??
        'https://img.internethaber.com/rcman/Cw1280h720q95gc/storage/files/images/2019/02/18/getiryemek-nedir-vhh8_cover.jpg'
      "
      alt=""
    />
  </div>

  <div *dxTemplate="let data of 'buttonTemplate'">
    <div class="d-flex align-items-center justify-content-end">
      <div class="border-right mr-2">
        <dx-button
          *ngIf="getNextStatusText(data.data.status, data.data) != null"
          [id]="'approve-btn-' + data.value"
          class="mr-2"
          icon="check"
          type="success"
          (onClick)="updateOrder(data.data)"
        >
        </dx-button>
        <dx-button
          [id]="'cancel-btn-' + data.value"
          icon="remove"
          class="mr-2"
          type="danger"
          (onClick)="cancelOrder(data.data)"
        >
        </dx-button>
      </div>

      <div>
        <dx-button
          [id]="'view-btn-' + data.value"
          [routerLink]="'/orders/' + data.value"
          icon="search"
          stylingMode="outlined"
          class="mr-2"
          type="default"
        >
        </dx-button>
        <dx-button
          [id]="'print-btn-' + data.value"
          stylingMode="outlined"
          icon="print"
          class="mr-2"
          type="normal"
        >
        </dx-button>
      </div>

      <dx-tooltip
        [target]="'#print-btn-' + data.value"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data = data; of: 'content'">Fiş Yazdır</div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel-btn-' + data.value"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data = data; of: 'content'">İptal Et</div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#view-btn-' + data.value"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data = data; of: 'content'">Detay Görüntüle</div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#approve-btn-' + data.value"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let t = data; of: 'content'">{{getNextStatusText(data.data.status, data.data)}} olarak işaretle</div>
      </dx-tooltip>
    </div>
  </div>
</dx-data-grid>
