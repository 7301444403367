<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"
    >
    </dxi-item>
    <!-- <dxi-item
      location="before"
      cssClass="header-title"
      *ngIf="title"
      [text]="title"
    >
    </dxi-item> -->

    <dxi-item location="before" cssClass="header-title" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
          <img src="assets/logo.jpg" class="logo" alt="">
      </div>
    </dxi-item>

    <dxi-item location="after" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button
          (onClick)="navigateTo('pos')"
          stylingMode="outlined"
          text="POS"
          type="default"
          [width]="120"
        >
        </dx-button>
      </div>
    </dxi-item>

    <dxi-item location="after" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <div class="provider-wrapper">
          <div class="provider active">
            <img src="../../../../assets/siparissaati_logo.png" alt="" />
          </div>

          <div class="provider passive">
            <img src="../../../../assets/getir_logo.jpg" alt="" />
          </div>

          <div class="provider active">
            <img src="../../../../assets/yemeksepeti_logo.jpg" alt="" />
          </div>

          <div class="provider passive">
            <img src="../../../../assets/trendyol_logo.png" alt="" />
          </div>
        </div>
      </div>
    </dxi-item>

    <!-- <dxi-item location="after" menuItemTemplate="themeSelector">
      <div *dxTemplate="let data of 'item'">
        <theme-selector></theme-selector>
      </div>
    </dxi-item> -->
    <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button
          class="user-button authorization"
          width="210px"
          height="100%"
          stylingMode="text"
        >
          <div *dxTemplate="let data of 'content'">
            <app-user-panel
              [user]="user"
              [menuItems]="userMenuItems"
              menuMode="context"
            ></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>
    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel
        [user]="user"
        [menuItems]="userMenuItems"
        menuMode="list"
      ></app-user-panel>
    </div>
  </dx-toolbar>
</header>
