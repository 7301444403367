export enum Provider {
    SiparisSaati,
    Getir,
    YemekSepeti,
    Trendyol,
    Pos
}

export default class ProviderUtils {
  
   static ProviderColumnNameDictionary = {
    ["Getir"]: "Getir",
    ["YemekSepeti"]: "YemekSepeti",
    ["Trendyol"]: "Trendyol"
   }

   static ProviderColumnNamesArray = ["Getir", "YemekSepeti", "Trendyol"];
}