import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpService } from 'src/app/shared/services/http.service';
import { ItemResourceDto } from 'src/app/shared/models/item.model';

@Component({
    selector: 'app-point-of-sale',
    templateUrl: 'point-of-sale.component.html',
    styleUrls: ['./point-of-sale.component.scss'],
})
export class PointOfSaleComponent implements OnInit, OnDestroy {

    menu: any;
    availableCategories: string[] = [];
    filteredItems: ItemResourceDto[] = [];
    readonly allKey = 'Tümü';
    selectedCategory = this.allKey;

    orderDataSource = [
        {summary: 'Masa 3 / Hazırlanıyor'},
        {summary: 'Masa 23 / Servis'},
        {summary: 'Masa 4 / Hazırlanıyor'},
        {summary: 'Masa 8 / Hazırlanıyor'},
        {summary: 'Masa 15 / Hazırlanıyor'},
        {summary: 'Masa 65 / Hazırlanıyor'},
    ]

    constructor(
        public location: Location, 
        private httpService: HttpService ) {
    }

    ngOnInit(): void {
        document.body.classList.add('pos-screen');
        this.getMenu();
    }

    ngOnDestroy(): void {
       document.body.classList.remove('pos-screen');
    }

    getMenu() {
        this.httpService.get('Item/menu').subscribe(res => {
            this.availableCategories = [this.allKey, ... Object.keys(res)];
            let allItems = [];
            Object.keys(res).forEach(key => {
                allItems = allItems.concat(...res[key]);
            });

            res[this.allKey] = allItems;
            this.menu = res;
            this.filteredItems = allItems;
            
        })
    }

    changeCategory(selection) {        
        this.selectedCategory = selection;
        this.filteredItems = this.menu[selection];
    }



    back = function() {    
        this.location.back();
    }.bind(this);
    
}
