import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elapsedMinutes',
  pure: false
})
export class ElapsedMinutesPipe implements PipeTransform {

  transform(value: Date | string | number): number {
    // Convert the input value to a Date object
    const givenTime = new Date(value);
    const currentTime = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = currentTime.getTime() - givenTime.getTime();

    // Convert the difference from milliseconds to minutes
    const elapsedMinutes = Math.floor(timeDifference / 60000);

    return elapsedMinutes;
  }
}