import { Status } from "./status.enum";

export class ItemCategory  {
    id: string;
    thumbnail: string;
    cover: string;

    name: string;
    slug: string;
    description: string;

    order: number;

    parentItemCategoryId: string | null;
    restaurantId: string;

    status: Status | null = Status.Active
}


export class ItemCategoryRequestDto {
    id: string | null;
    name: string;
    description: string;
        
    //TODO: Image should be stored and we should resize for thmubnail and cover
    image: string;
    thumb: string;
    parentId: string | null;
    status: Status;
}