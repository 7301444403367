import { Component, OnInit } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';
import { ItemAttribute } from "src/app/shared/models/item-attribute.model";

@Component({
    selector: 'app-product-attributes',
    templateUrl: `./product-attributes.component.html`,
    styleUrls: ['./product-attributes.component.scss'],
})
export class ProductAttributesComponent implements OnInit {
  dataSource: any[] = [];
  image: File[] = [];

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;  
  itemAttribute: ItemAttribute = new ItemAttribute();


  get isFormValid(): boolean {
    return this.itemAttribute.name && this.itemAttribute.name.length > 0 && this.itemAttribute.status != null;
  }

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.getCaegories();
  }

  getCaegories() {
    this.httpService
      .get(`ItemAttribute`, { params: StatusUtils.GetStatusParams() })
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.httpService.get(`ItemAttribute/${id}`).subscribe(res => {
      this.itemAttribute = res;
      this.openPopup();
    })
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Kaydı silmek istediğinizden emin misiniz?", "Silme Onayı");
    if (!result) {
      return;
    }

    this.httpService.delete(`ItemAttribute/${id}`).subscribe(res => {
      this.getCaegories();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.itemAttribute.id) {
      this.httpService.put('ItemAttribute', this.itemAttribute).subscribe(res => {
        this.getCaegories();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('ItemAttribute', this.itemAttribute).subscribe(res => {
        this.getCaegories();
        this.onCloseClicked();
      })
    }


  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.itemAttribute = new ItemAttribute();
  }.bind(this);

}
