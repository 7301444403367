import { HttpParams } from "@angular/common/http";

export enum Status {
  Active,
  Passive,
  Deleted,
}

export default class StatusUtils {
  static GetStatusParams = (statuses: Status[] = [Status.Active, Status.Passive]) => {
    let params = new HttpParams();
    statuses.forEach((status) => {
      params = params.append("statuses", status.toString());
    });

    return params;
  };

  static StatusNamesDictionary = {
    [Status.Active]: "Aktif",
    [Status.Passive]: "Pasif",
    [Status.Deleted]: "Silinmiş",
 }

 static StatusNamesLookup = [ 
    {id: Status.Active, name: StatusUtils.StatusNamesDictionary[Status.Active] }, 
    {id: Status.Passive, name: StatusUtils.StatusNamesDictionary[Status.Passive] }, 
    {id: Status.Deleted, name: StatusUtils.StatusNamesDictionary[Status.Deleted]  }
 ]

static StatusNamesWithoutDeletedLookup = [ 
  {id: Status.Active, name: StatusUtils.StatusNamesDictionary[Status.Active] }, 
  {id: Status.Passive, name: StatusUtils.StatusNamesDictionary[Status.Passive] }, 
]

 static StatusColors = {
    [Status.Active]: "success" as 'success' | 'danger' | 'normal',
    [Status.Passive]: "danger" as 'success' | 'danger' | 'normal',
    [Status.Deleted]: "normal" as 'success' | 'danger' | 'normal',
  }
}
