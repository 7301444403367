import { SharedEntity } from "./SharedEntity";
import { Status } from "./status.enum";

export class ItemRequest {
    id: string | null;
    name: string;
    itemCategoryId: string;
    categoryName: string | null;
    taxId: number | null;
    price: number;
    isFeatured: boolean;
    description: string;
    caution: string;
    status: Status;
    order: number;
    variations: ItemVariation[];
    image: string;
}

export class ItemVariation extends SharedEntity {
    itemId: string | null;
    itemAttributeId: string; // Doughnut Tercihi
    itemAttributeName: string | null; // Doughnut Tercihi
    name: string; // S�tl� leblebi�
    price: number; // 2321
    providerPrices: { [key: string]: number };
    caution: string;
    restaurantId: string;

}

export class ItemAttributeRequestDto {
    id: string | null;
    name: string;
    status: Status;

    order: number;
}


export class ItemExtraRequestDto {
    id: string | null;
    itemId: string;
    name: string;
    price: number;
    status: Status;
}

export class ItemResourceDto extends ItemRequest {
    itemAttributes: ItemAttributeRequestDto[];
    extras: ItemExtraRequestDto[];
}