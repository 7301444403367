import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, type OnInit } from '@angular/core';
import StatusUtils, { Status } from '../../models/status.enum';
import { DxButtonModule } from 'devextreme-angular';

@Component({
    selector: 'app-status-badge',
    standalone: true,
    imports: [
        CommonModule,
        DxButtonModule
    ],
    templateUrl: './status-badge.component.html',
    styleUrls: ['./status-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBadgeComponent implements OnInit {
    @Input() status: Status;

    readonly statusNames = StatusUtils.StatusNamesDictionary;
    readonly statusColors = StatusUtils.StatusColors;

    ngOnInit(): void {         
    }

}
