<app-page-header
  [titleText]="'Sipariş Listesi'"
  [hasAddButton]="false"
></app-page-header>

<div class="container-fluid dx-card dx-wide-card px-0">
  <div class="row align-items-center">
    <div class="col-md-4">
      <dx-tabs
        #withIconAndText
        [(selectedIndex)]="selectedTabIndex"
        [dataSource]="tabs"
        [orientation]="'horizontal'"
        [stylingMode]="'secondary'"
        [iconPosition]="'start'"
        [itemTemplate]="'tab-item'"
      >
        <div *dxTemplate="let data of 'tab-item'; let index = index">
          <div class="d-flex align-items-center tab-title">
            {{ data }}
            <span
              *ngIf="orderCounts[index] > 0"
              class="badge badge-danger ml-3"
              >{{ orderCounts[index] }}</span
            >
          </div>
        </div>
      </dx-tabs>
    </div>

    <div class="col">
      <div class="d-flex justify-content-end align-items-center">
        <div class="mr-2">Otomatik Yenile</div>
        <dx-switch #switch [value]="false"></dx-switch>
        <dx-button class="mx-3 ml-md-5" icon="refresh" (onClick)="reload()">
        </dx-button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="selectedTabIndex == 0">
  <dx-accordion
    #accordion
    class="dx-card wide-card custom-accourdion"
    [dataSource]="accordionPanels"
    [collapsible]="true"
    [multiple]="true"
    [animationDuration]="300"
    [selectedItems]="accordionPanels"
  >
    <div *dxTemplate="let data of 'title'; let index = index">
      <div class="d-flex align-items center">
        <span
          *ngIf="index == 0 ? newOrders.length > 0 : activeOrders.length > 0"
          class="badge badge-danger mr-1"
          >{{ index == 0 ? newOrders.length : activeOrders.length }}</span
        >
        <div class="header">
          {{ data }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let item of 'item'; let index = index">
      <app-order-table
        (onUpdateOrderClicked)="updateOrderStatus($event)"
        (onCancelOrderClicked)="openCancelReasonModal($event)"
        [dataSource]="index == 0 ? newOrders : activeOrders"
      ></app-order-table>
    </div>
  </dx-accordion>
</ng-container>

<ng-container *ngIf="selectedTabIndex == 1"> POS SİPARİŞLERİ </ng-container>

<ng-container *ngIf="selectedTabIndex == 2"> GEÇMİŞ SİPARİŞLER </ng-container>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Sipariş İptal"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="cancelPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Kaydet',
      onClick: cancelOrder
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Vazgeç',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">İptal Etme Nedeni*</div>
        <dx-radio-group
          [dataSource]="cancelReasons"
          [(value)]="selectedOrderForCancel.cancelReasonId"
          displayExpr="name"
          valueExpr="id"
        >
        </dx-radio-group>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Not</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="selectedOrderForCancel.note"
          [showClearButton]="true"
        ></dx-text-box>
      </div>
    </div>
  </div>
</dx-popup>
