<app-page-header
  [titleText]="'Ürün Görüntüle'"
  [hasAddButton]="false"
></app-page-header>

<dx-tab-panel
  class="dx-theme-background-color dx-card wide-card"
  width="100%"
  [animationEnabled]="true"
  [swipeEnabled]="true"
  [dataSource]="tabPanelItems"
  [tabsPosition]="'top'"
  [stylingMode]="'primary'"
  [iconPosition]="'start'"
>
  <div *dxTemplate="let tabPanelItem of 'item'">
    <div *ngIf="item" class="tabpanel-item">
      <div *ngIf="tabPanelItem.key == 0" class="container">
        <div class="row py-2">
          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">İsim</span>
              <dx-text-box
                class="w-75"
                stylingMode="outlined"
                [value]="item?.name"
                [readOnly]="true"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">Fiyat</span>
              <dx-number-box
                class="w-75"
                stylingMode="outlined"
                [value]="item?.price"
                [readOnly]="true"
              >
              </dx-number-box>
            </div>
          </div>
          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">Kategori</span>
              <dx-text-box
                class="w-75"
                stylingMode="outlined"
                [value]="item?.categoryName"
                [readOnly]="true"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">Vergi</span>
              <dx-text-box
                class="w-75"
                stylingMode="outlined"
                [value]="(item?.taxId??'') + ''"
                [readOnly]="true"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">Öne Çıkan</span>
              <dx-text-box
                class="w-75"
                stylingMode="outlined"
                [value]="item?.isFeatured ? 'Evet' : 'Hayır'"
                [readOnly]="true"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">Durum</span>
              <dx-text-box
                class="w-75"
                stylingMode="outlined"
                [value]="productNames[item?.status]"
                [readOnly]="true"
              >
              </dx-text-box>
            </div>
          </div>

          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">Açıklama</span>
              <dx-text-area
                class="w-75"
                stylingMode="outlined"
                [value]="item?.description"
                [readOnly]="true"
              >
              </dx-text-area>
            </div>
          </div>

          <div class="col-12 col-sm-6 py-2">
            <div class="db-list-item">
              <span class="db-list-item-title">Uyarı</span>
              <dx-text-area
                class="w-75"
                stylingMode="outlined"
                [value]="item?.caution ?? ''"
                [readOnly]="true"
              >
              </dx-text-area>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="tabPanelItem.key == 1">
        <!--TODO: YAPILACAK -->
            Yapım Aşamasında
      </div>
      <div class="variation-holder" *ngIf="tabPanelItem.key == 2">
        <app-page-header
          [titleText]="''"
          [hasAddButton]="true"
          (onAddClicked)="onAddVariatonClicked()"
        ></app-page-header>

        <dx-data-grid
          class="dx-card wide-card"
          [dataSource]="item.variations"
          keyExpr="id"
          [showColumnLines]="true"
          [showRowLines]="true"
          [showBorders]="true"
          [columnAutoWidth]="true"
          [columnHidingEnabled]="true"
        >
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-group-panel [visible]="true"></dxo-group-panel>

          <dxo-pager
            [showPageSizeSelector]="true"
            [showInfo]="true"
          ></dxo-pager>
          <dxo-filter-row [visible]="true"></dxo-filter-row>

          <dxi-column dataField="name" caption="İsim"> </dxi-column>
          <dxi-column
            dataField="itemAttributeName"
            caption="Grup"
            [groupIndex]="0"
          ></dxi-column>
          <dxi-column dataField="price" caption="Ek Fiyat"> </dxi-column>

          <dxi-column
            dataField="status"
            caption="Durum"
            [width]="180"
            cellTemplate="statusTemplate"
          >
            <dxo-lookup
              [dataSource]="lookupStatus"
              valueExpr="id"
              displayExpr="name"
            ></dxo-lookup>
          </dxi-column>

          <dxi-column
            dataField="id"
            caption="#"
            [width]="280"
            [allowFiltering]="false"
            [allowHiding]="false"
            [allowSearch]="false"
            [allowSorting]="false"
            cellTemplate="buttonTemplate"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'statusTemplate'">
            <app-status-badge [status]="data.value"></app-status-badge>
          </div>

          <div *dxTemplate="let data of 'providerTemplate'">
            <dx-button icon="link" [type]="data.value ? 'success' : 'normal'">
            </dx-button>
          </div>

          <div *dxTemplate="let data of 'buttonTemplate'">
            <div class="d-flex align-items-center justify-content-end">
              <dx-button
                class="mr-2"
                icon="edit"
                text="Düzenle"
                type="default"
                (onClick)="onVariationEditClicked(data.data)"
                [width]="120"
              >
              </dx-button>

              <dx-button
                stylingMode="outlined"
                icon="trash"
                text="Sil"
                (onClick)="onVariationDeleteClicked(data.value)"
                type="danger"
                [width]="120"
              >
              </dx-button>
            </div>
          </div>
        </dx-data-grid>
      </div>

      <div *ngIf="tabPanelItem.key == 3">
        <app-page-header
          [titleText]="''"
          [hasAddButton]="true"
          (onAddClicked)="onAddExtraClicked()"
        ></app-page-header>

        <dx-data-grid
          class="dx-card wide-card"
          [dataSource]="item.extras"
          keyExpr="id"
          [showColumnLines]="true"
          [showRowLines]="true"
          [showBorders]="true"
          [columnAutoWidth]="true"
          [columnHidingEnabled]="true"
        >
          <dxo-paging [pageSize]="50"></dxo-paging>

          <dxo-pager
            [showPageSizeSelector]="true"
            [showInfo]="true"
          ></dxo-pager>
          <dxo-filter-row [visible]="true"></dxo-filter-row>

          <dxi-column dataField="name" caption="İsim"> </dxi-column>
          <dxi-column dataField="price" caption="Ek Fiyat"> </dxi-column>

          <dxi-column
            dataField="status"
            caption="Durum"
            [width]="180"
            cellTemplate="statusTemplate"
          >
            <dxo-lookup
              [dataSource]="lookupStatus"
              valueExpr="id"
              displayExpr="name"
            ></dxo-lookup>
          </dxi-column>

          <dxi-column
            dataField="id"
            caption="#"
            [width]="280"
            [allowFiltering]="false"
            [allowHiding]="false"
            [allowSearch]="false"
            [allowSorting]="false"
            cellTemplate="buttonTemplate"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'statusTemplate'">
            <app-status-badge [status]="data.value"></app-status-badge>
          </div>

          <div *dxTemplate="let data of 'providerTemplate'">
            <dx-button icon="link" [type]="data.value ? 'success' : 'normal'">
            </dx-button>
          </div>

          <div *dxTemplate="let data of 'buttonTemplate'">
            <div class="d-flex align-items-center justify-content-end">
                <dx-button
                class="mr-2"
                icon="edit"
                text="Düzenle"
                type="default"
                (onClick)="onExtraEditClicked(data.data)"
                [width]="120"
              >
              </dx-button>

              <dx-button
                stylingMode="outlined"
                icon="trash"
                text="Sil"
                (onClick)="onExtraDeleteClicked(data.value)"
                type="danger"
                [width]="120"
              >
              </dx-button>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </div>
</dx-tab-panel>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Varyasyon"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isVariationPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Kaydet',
      disabled: !isVariationFormValid,
      onClick: onVariationSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Vazgeç',
      onClick: onVariationCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">İsim*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="itemVariation.name"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Opsiyon Grubu*</div>
        <dx-select-box
          [dataSource]="attributeDataSource"
          [(value)]="itemVariation.itemAttributeId"
          valueExpr="id"
          stylingMode="outlined"
          displayExpr="name"
          [searchEnabled]="true"
          searchExpr="name"
          [showClearButton]="true"
        >
        </dx-select-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Ek Fiyat*</div>
        <dx-number-box
          stylingMode="outlined"
          [(value)]="itemVariation.price"
          [showClearButton]="true"
        ></dx-number-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Durum*</div>
        <dx-select-box
          [dataSource]="lookupStatusWithoutDelete"
          [(value)]="itemVariation.status"
          valueExpr="id"
          stylingMode="outlined"
          displayExpr="name"
          [showClearButton]="true"
        >
        </dx-select-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Uyarı</div>
        <dx-text-area
          stylingMode="outlined"
          [(value)]="itemVariation.caution"
        ></dx-text-area>
      </div>
    </div>
  </div>
</dx-popup>


<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Ekstra"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isExtraPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Kaydet',
      disabled: !isExtraFormValid,
      onClick: onExtraSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Vazgeç',
      onClick: onExtraCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">İsim*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="itemExtra.name"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Ek Fiyat*</div>
        <dx-number-box
          stylingMode="outlined"
          [(value)]="itemExtra.price"
          [showClearButton]="true"
        ></dx-number-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Durum*</div>
        <dx-select-box
          [dataSource]="lookupStatusWithoutDelete"
          [(value)]="itemExtra.status"
          valueExpr="id"
          stylingMode="outlined"
          displayExpr="name"
          [showClearButton]="true"
        >
        </dx-select-box>
      </div>
    </div>
  </div>
</dx-popup>