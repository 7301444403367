<div class="d-flex w-100">
  <dx-tabs
    #withText
    [selectedIndex]="0"
    [dataSource]="tabsWithText"
    width="auto"
  ></dx-tabs>
  <div class="dx-field-value w-100">
    <dx-date-range-box class="d-flex w-100" [(value)]="currentValue">
    </dx-date-range-box>
  </div>
</div>

<div class="row mb-2 mt-2">
  <div class="col-md-3">
    <div class="card px-3 py-2 shadow">
      <div class="label">Toplam Satış</div>
      <div class="value">62.350,53 TL</div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card px-3 py-2 shadow">
      <div class="label">Toplam Sipariş</div>
      <div class="value">2350</div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card px-3 py-2 shadow">
      <div class="label">Toplam İade</div>
      <div class="value">2.350,53 TL</div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card px-3 py-2 shadow">
      <div class="label">Toplam Gelir</div>
      <div class="value">60.000,00 TL</div>
    </div>
  </div>
</div>

<div class="row mb-2">
  <div class="col-md-8">
    <div class="card px-3 py-2 shadow">
      <dx-chart id="chart" [dataSource]="salesInfo">
        <dxi-series valueField="pos" name="Pos"></dxi-series>
        <dxi-series valueField="yemeksepeti" name="Yemek Sepeti"></dxi-series>
        <dxi-series valueField="getir" name="Getir"></dxi-series>
        <dxi-series valueField="siparissaati" name="Sipariş Saati"></dxi-series>
        <dxi-series valueField="trendyol" name="Trendyol"></dxi-series>
        <dxi-series
          axis="total"
          type="spline"
          valueField="total"
          name="Toplam"
          color="#008fd8"
        >
        </dxi-series>
        <dxo-common-series-settings argumentField="title" type="stackedBar">
        </dxo-common-series-settings>
        <dxo-legend
          verticalAlignment="top"
          horizontalAlignment="center"
          itemTextPosition="left"
        >
        </dxo-legend>
        <dxi-value-axis name="total" position="left">
          <dxo-grid [visible]="true"></dxo-grid>
        </dxi-value-axis>
        <dxo-title text="Satış Raporu"> </dxo-title>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
        </dxo-tooltip>
      </dx-chart>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card px-3 py-2 shadow">
      <dx-pie-chart
        id="pie"
        title="Satış Kanalları"
        [dataSource]="saleChannels"
      >
        <dxo-legend
          verticalAlignment="top"
          horizontalAlignment="center"
          itemTextPosition="left"
        >
        </dxo-legend>
        <dxi-series argumentField="title" valueField="total">
          <dxo-label
            [visible]="true"
            position="columns"
            [customizeText]="customizeLabel"
          >
            <dxo-font [size]="16"></dxo-font>
            <dxo-connector [visible]="true" [width]="0.5"></dxo-connector>
          </dxo-label>
        </dxi-series>
        <dxo-size [width]="500"></dxo-size>
        <dxo-export [enabled]="true"></dxo-export>
      </dx-pie-chart>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-8">
    <div class="label ml-3">En Son Siparişler</div>
    <dx-data-grid
      id="gridContainer"
      keyExpr="ID"
      [showBorders]="true"
    >
    </dx-data-grid>
  </div>

  <div class="col-md-4">
      <div class="label ml-3">En Çok Satan Ürünler</div>
      <dx-data-grid
        id="gridContainer"
        [dataSource]="mostSellingItems"
        keyExpr="ID"
        [columns]="['Ürün', 'Sipariş Sayısı', 'Toplam Tutar']"
        [showBorders]="true"
      >
      </dx-data-grid>
    </div>
</div>
