<form class="create-account-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item dataField="email" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }">
      <dxi-validation-rule type="required" message="Email zorunludur"></dxi-validation-rule>
      <dxi-validation-rule type="email" message="Email geçerli değild"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="password" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Parola', mode: 'password' }">
      <dxi-validation-rule type="required" message="Parola zorunludur"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="confirmedPassword" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Parola Doğrula', mode: 'password' }">
      <dxi-validation-rule type="required" message="Parola zorunludur"></dxi-validation-rule>
      <dxi-validation-rule type="custom" message="Parolalar eşleşmiyor" [validationCallback]="confirmPassword">
      </dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item>
      <div class='policy-info'>
        Hesap oluşturarak, <a routerLink="#">Şartlar ve Koşullar</a> ve <a routerLink="#">Gizlilik</a> sözleşmelerini kabul etmiş sayılırsınız.
      </div>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'createAccountTemplate'">
      </dxo-button-options>
    </dxi-item>

    <dxi-item>
      <div class="login-link">
        Hesabınız var mı? <a routerLink="/login">Giriş Yap</a>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'createAccountTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
          </ng-container>
  
          <ng-template #notLoading>Hesap Oluştur</ng-template>
        </span>
      </div>
    </ng-container>

  </dx-form>
</form>
